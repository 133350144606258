
/**
 * @roxi/routify 2.18.9
 * File generated Mon Jan 23 2023 23:10:59 GMT+0300 (GMT+03:00)
 */

export const __version = "2.18.9"
export const __timestamp = "2023-01-23T20:10:59.658Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/admins/classes/:id",
              "id": "_admins_classes__id",
              "component": () => import('../src/pages/admins/classes/[id].svelte').then(m => m.default)
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/admins/classes/index",
              "id": "_admins_classes_index",
              "component": () => import('../src/pages/admins/classes/index.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/admins/classes/new",
              "id": "_admins_classes_new",
              "component": () => import('../src/pages/admins/classes/new.svelte').then(m => m.default)
            }
          ],
          "path": "/admins/classes"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/admins/emails/index",
              "id": "_admins_emails_index",
              "component": () => import('../src/pages/admins/emails/index.svelte').then(m => m.default)
            }
          ],
          "path": "/admins/emails"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/admins/index",
          "id": "_admins_index",
          "component": () => import('../src/pages/admins/index.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/admins/invoices/index",
              "id": "_admins_invoices_index",
              "component": () => import('../src/pages/admins/invoices/index.svelte').then(m => m.default)
            }
          ],
          "path": "/admins/invoices"
        },
        {
          "isPage": true,
          "path": "/admins/notifications",
          "id": "_admins_notifications",
          "component": () => import('../src/pages/admins/notifications.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/admins/settings/index",
              "id": "_admins_settings_index",
              "component": () => import('../src/pages/admins/settings/index.svelte').then(m => m.default)
            }
          ],
          "path": "/admins/settings"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/admins/users/:id",
              "id": "_admins_users__id",
              "component": () => import('../src/pages/admins/users/[id].svelte').then(m => m.default)
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/admins/users/index",
              "id": "_admins_users_index",
              "component": () => import('../src/pages/admins/users/index.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/admins/users/new",
              "id": "_admins_users_new",
              "component": () => import('../src/pages/admins/users/new.svelte').then(m => m.default)
            }
          ],
          "path": "/admins/users"
        }
      ],
      "isLayout": true,
      "path": "/admins",
      "id": "_admins__layout",
      "component": () => import('../src/pages/admins/_layout.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/enroll",
      "id": "_enroll",
      "component": () => import('../src/pages/enroll.svelte').then(m => m.default)
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/reset-password",
      "id": "_resetPassword",
      "component": () => import('../src/pages/reset-password.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/teachers/classes/:id",
              "id": "_teachers_classes__id",
              "component": () => import('../src/pages/teachers/classes/[id].svelte').then(m => m.default)
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/teachers/classes/index",
              "id": "_teachers_classes_index",
              "component": () => import('../src/pages/teachers/classes/index.svelte').then(m => m.default)
            }
          ],
          "path": "/teachers/classes"
        },
        {
          "isPage": true,
          "path": "/teachers/notifications",
          "id": "_teachers_notifications",
          "component": () => import('../src/pages/teachers/notifications.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/teachers/users/:id",
              "id": "_teachers_users__id",
              "component": () => import('../src/pages/teachers/users/[id].svelte').then(m => m.default)
            }
          ],
          "path": "/teachers/users"
        }
      ],
      "isLayout": true,
      "path": "/teachers",
      "id": "_teachers__layout",
      "component": () => import('../src/pages/teachers/_layout.svelte').then(m => m.default)
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

